import React, { Fragment, useEffect, useState } from "react";
import { Radios } from "../dom/Radios";
import PropTypes from "prop-types";
import { ErrorContainer } from "../dom/ErrorContainer";
import { Genders } from "../enums/Genders";
import { Spinner } from "../dom/Spinner";
import { BirthDatePicker } from "../dom/BirthDatePicker";
import { PersonPhoto } from "./PersonPhoto";
import { Helpers } from "../helpers/Helpers";
import * as Inputmask from "inputmask";

const axios = require('axios').default;

export const PersonForm = props => {

    props.person.birthdate = props.person.birthdate == "0000-00-00" ? null : props.person.birthdate;

    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [person, setPerson] = useState(props.person);
    const [personBirthDate, setPersonBirthDate] = useState({});
    const [sourcePersonPhoto, setSourcePersonPhoto] = useState(props.person.photo);
    const [apply, setApply] = useState(false);
    const formId = "person_edit_form";
    const fileInputId = "personPhotoFile";

    useEffect(() => {
        const setValidation = () => {
            $("#" + formId).validate(
                {
                    rules: {
                        "person[f]": {
                            required: true,
                        },
                        "person[i]": {
                            required: true,
                        },
                    },
                },
            );
        };
        setValidation();

        Inputmask({
            mask: "999-999-999 99",
            showMaskOnHover: false,
            jitMasking: true
          }).mask("snils");

    }, []);

    useEffect(() => {
        if (!personBirthDate) {
            let date = Helpers.getDateFromString(person.birthdate);
            setPersonBirthDate(date);
        }

    }, [person]);

    const handleGenderChange = (e) => {
        const { value } = e.target;
        setPerson({ ...person, gender: value });
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setPerson({ ...person, [id]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrors([]);
        let $form = $("#" + formId);

        if (!$form.valid()) {
            return false;
        }
        setLoading(true);

        const formData = new FormData();
        const file = document.getElementById(fileInputId).files[0];
        if (file) {
            const delFile = document.getElementById('deletedFile').value;
            formData.append("deletedFile", delFile);
        }
        formData.append("element", JSON.stringify(person));
        formData.append("backUrl", props.backUrl);
        formData.append(fileInputId, file);
        axios.post('/api/persons/save/',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        )
            .then((response) => {
                if (props.backUrl && props.backUrl.length > 0 && !apply) {
                    window.location.href = props.backUrl;
                    return false;
                }
                setLoading(false);
                props.onPersonChange?.(response.data);
            })
            .catch((error) => {
                setErrors(errors => [...errors, error.response.data.errors]);
                setLoading(false);
            });
    };

    const handleApplySubmit = (event) => {
        event.preventDefault();
        setApply(true);
        handleSubmit(event);
    };

    const cancelBtnClick = (event) => {
        props.onCancel?.();
        if (props.backUrl) {
            window.location.href = props.backUrl;
        }
        event.preventDefault();
    };

    const getBirthDateAsObject = () => {
        return person.birthdate ? Helpers.getDateFromString(person.birthdate) : "";
    };

    const setBirthdate = (date) => {
        let strDate = "";
        if (date) {
            strDate = date.toLocaleDateString();
        }
        setPerson({ ...person, birthdate: strDate });
    };

    const setPhoto = (photoSrc) => {
        setPerson({ ...person, photo: photoSrc || null });
    };

    return (
        <Fragment>
            {isLoading
                ? <Spinner />
                : <form id={formId} name={formId} method={"POST"}>

                    <input name="person[ID]" type="hidden" value={person.ID || ""} />

                    {errors.length > 0 ? <ErrorContainer arErrors={errors} /> : ""}

                    <Fragment>

                        <div className="row mb-3">
                            <div className="col-12 col-md-3 text-center border border-light">
                                <PersonPhoto
                                    edit={true}
                                    onPhotoChange={setPhoto}
                                    fileInputId={fileInputId}
                                    gender={person.gender}
                                    sourcePersonPhoto={sourcePersonPhoto}
                                    photoSrc={person.photo} />
                            </div>

                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="f">Фамилия<span className="required"> </span></label>
                                    <input id="f" className="form-control" name="person[f]" type="text"
                                        autoComplete={"off"}
                                        value={person.f || ""} onChange={handleChange} />
                                </div>

                                <div className="form-group">
                                    <label className="display_block" htmlFor="i">Имя<span
                                        className="required"> </span></label>
                                    <input className="form-control" id="i" name="person[i]" onChange={handleChange}
                                        autoComplete={"off"}
                                        type="text"
                                        value={person.i || ""} />
                                </div>

                                <div className="form-group">
                                    <label className="display_block" htmlFor="o">Отчество</label>
                                    <input className="form-control" id="o" name="person[o]" onChange={handleChange}
                                        autoComplete={"off"}
                                        type="text"
                                        value={person.o || ""} />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mb-3">
                            <div className="col-12 col-md-6 d-flex">
                                <Radios
                                    onChange={handleGenderChange}
                                    radios={Genders}
                                    name={"person[gender]"}
                                    checked={person.gender || "n"} />
                            </div>

                            <div className="form-group col-12 col-md-3">
                                <label className="d-block" htmlFor="birthdate">День рождения</label>
                                <BirthDatePicker
                                    name={"person[birthdate]"}
                                    dateFormat={"dd.MM.yyyy"}
                                    selected={person.birthdate || ""}
                                    onChange={setBirthdate} />

                            </div>
                            <div className="form-group col-12 col-md-3">
                                <label className="d-block" htmlFor="snils">СНИЛС</label>
                                <input
                                    autoComplete={"off"}
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control contacts_group"
                                    id="snils"
                                    name="person[snils]"
                                    placeholder="СНИЛС"
                                    value={person.snils || ""} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="form-group col-12 col-lg-6">
                                <label htmlFor="phone">Телефон</label>
                                <input
                                    autoComplete={"off"}
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control contacts_group"
                                    id="phone"
                                    name="person[phone]"
                                    placeholder="Телефон"
                                    value={person.phone || ""} />
                            </div>
                            <div className="form-group col-12 col-lg-6">
                                <label htmlFor="email">Электронный адрес</label>
                                <input
                                    autoComplete={"off"}
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control contacts_group"
                                    id="email"
                                    name="person[email]"
                                    placeholder="Электронный адрес"
                                    value={person.email || ""} />
                            </div>
                        </div>

                        <div className={"text-muted small"}>
                            Обязательно заполните или телефон или электронный адрес!
                        </div>

                        <div className="btn_container">
                            {props.context ?
                                <button className="btn btn-primary" onClick={handleApplySubmit}
                                    name="apply">Сохранить
                                </button>
                                :
                                <button className="btn btn-primary" name="submit" onClick={handleSubmit}>Сохранить и
                                    закрыть</button>
                            }
                            <button className="btn btn-secondary-outline" onClick={cancelBtnClick}>Отменить</button>
                        </div>

                    </Fragment>
                </form>
            }
        </Fragment>
    );
};

PersonForm.propTypes = {
    context: PropTypes.number,
    person: PropTypes.object,
    backUrl: PropTypes.string,
    onPersonChange: PropTypes.func,
    onCancel: PropTypes.func,
};
